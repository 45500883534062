/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  height: 100%;
  font-family: Inter, sans-serif;
}

#__next {
  /* @apply min-h-screen; */
  min-height: 100vh;
  margin: 0 auto;
}
.container {
  max-width: 1280px !important;
}
.p-tooltip-text {
  background-color: #ffeee6 !important;
  color: #893f2b !important;
  font-size: 0.8rem !important;
}
.p-tooltip-arrow {
  border-block-color: #893f2b !important;
}
.filter-orange {
  filter: invert(42%) sepia(100%) saturate(3990%) hue-rotate(2deg)
    brightness(105%) contrast(105%);
}
.filter-primary {
  filter: invert(7%) sepia(11%) saturate(4149%) hue-rotate(333deg)
    brightness(94%) contrast(97%);
}
.filter-secondary {
  filter: invert(94%) sepia(25%) saturate(148%) hue-rotate(337deg)
    brightness(89%) contrast(88%);
}
.react-datepicker__input-container input {
  width: 100%;
  border: 1px solid rgb(230, 73, 0);
  outline: none;
  border-radius: 5px;
  font-size: 15px;
}
.p-dialog-title {
  font-family: Inter, sans-serif !important;
  font-weight: 500 !important;
  font-size: 1.3rem !important;
}

.react-datepicker__input-container .react-datepicker__close-icon::after {
  background-color: rgb(230, 73, 0);
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: rgb(230, 73, 0) !important;
  background-color: rgb(230, 73, 0) !important;
  box-shadow: none !important;
}

.custom-table .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #893f2b !important;
}

.custom-table .p-sortable-column .p-sortable-column-icon {
  color: #e6b68b !important;
  font-size: 0.75rem !important;
}
.custom-table .p-sortable-column:focus {
  box-shadow: none !important;
}
.custom-table .p-column-filter-menu-button:focus {
  box-shadow: none !important;
}
.custom-table .p-link:focus {
  box-shadow: none !important;
}
.custom-table .p-column-filter-menu-button-active {
  background: #fff !important;
  color: #893f2b !important;
}
.custom-table .p-checkbox .p-checkbox-box {
  border: 1px solid #893f2b !important;
  background: #fff !important;
  color: #893f2b !important;
}
.custom-table .p-checkbox .p-checkbox-box:focus {
  box-shadow: none !important;
}
.custom-table .p-checkbox .p-checkbox-box.p-highlight {
  background: #893f2b !important;
}
.custom-table.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #fff6f2 !important;
}
.custom-filter .p-column-filter-buttonbar .p-button.p-button-outlined {
  border-color: #893f2b !important;
  background-color: transparent !important;
}
.custom-filter .p-column-filter-buttonbar .p-button {
  background: #ffeee6 !important;
  color: #893f2b !important;
  border-color: #ffeee6 !important;
}
.custom-filter .p-column-filter-buttonbar .p-button {
  width: 100% !important;
  padding: 8px 16px;
}
.custom-filter .p-column-filter-buttonbar {
  column-gap: 1rem !important;
  padding: 0 !important;
}
.custom-filter .p-column-filter-buttonbar .p-button:focus {
  box-shadow: none !important;
}
.custom-filter .p-column-filter-constraints .p-column-filter-constraint {
  padding: 0 !important;
}
.custom-filter {
  row-gap: 1rem !important;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.custom-multiselect-filter .p-multiselect-label {
  padding: 0.5rem 0.75rem !important;
  font-size: 14px !important;
  color: #893f2b !important;
}
.custom-multiselect-filter {
  border-color: #893f2b !important;
}
.custom-multiselect-filter .pi {
  font-size: 14px !important;
  color: #893f2b;
}
.custom-multiselect-filter:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border-color: #893f2b;
}
.custom-multiselect-filter-panel .p-multiselect-close {
  box-shadow: none !important;
  height: 1.75rem !important;
}
.custom-multiselect-filter-panel .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #893f2b;
  background: #893f2b;
}
.custom-multiselect-filter-panel .p-checkbox .p-checkbox-box.p-focus {
  box-shadow: none !important;
  border-color: #893f2b !important;
}
.custom-multiselect-filter-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight {
  background: #ffeee6 !important;
  color: #893f2b !important;
}
.custom-multiselect .p-multiselect-token {
  background: #ffeee6 !important;
  color: #893f2b !important;
}
.custom-multiselect-filter-panel
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  border-color: #893f2b;
  background: #893f2b;
}
.custom-multiselect-filter-panel
  .p-multiselect-items
  .p-multiselect-item:focus {
  box-shadow: none !important;
}
.custom-multiselect-filter-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.5rem 1rem !important;
}
.custom-autocomplete .p-inputtext {
  padding: 0.5rem 0.75rem !important;
  box-shadow: none !important;
  border-color: #893f2b !important;
  border-right: 0;
}
.custom-autocomplete .p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #893f2b !important;
}
.custom-autocomplete .p-autocomplete-dropdown {
  padding: 0.5rem 0.75rem !important;
  background: white !important;
  color: #893f2b !important;
  border-color: #893f2b !important;
  border-left: 0;
}
.custom-autocomplete .p-autocomplete-dropdown:focus {
  background: white !important;
  border-color: #893f2b !important;
  box-shadow: none !important;
}
.custom-calendar .p-calendar-label {
  padding: 0.5rem 0.75rem !important;
  font-size: 14px !important;
  color: #893f2b !important;
}
.custom-calendar .p-inputtext {
  background: white !important;
  color: #893f2b !important;
  box-shadow: none !important;
  border-color: #893f2b !important;
}
.custom-calendar-input .p-inputtext {
  padding: 8px 12px !important;
  font-size: 16px !important;
  line-height: normal;
}
.custom-calendar .p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #893f2b !important;
}
.custom-calendar-panel .p-highlight {
  color: #893f2b !important;
  background-color: #ffeee6 !important;
}
.custom-calendar-panel span {
  box-shadow: none !important;
}
.custom-filter
  .p-column-filter-constraints
  .p-column-filter-constraint
  .p-dropdown {
  background: white !important;
  color: #893f2b !important;
}
.p-dropdown-item.p-highlight {
  background: #ffeee6 !important;
  color: #893f2b !important;
}
.p-dropdown.p-focus {
  box-shadow: none !important;
  border-color: #893f2b !important;
}
.custom-select .p-dropdown-label {
  display: flex;
  align-items: center;
}
.custom-select .p-dropdown .p-dropdown-trigger {
  color: #893f2b;
}

.custom-select.small.p-dropdown .p-dropdown-trigger {
  width: 1.5rem;
}
.custom-select.medium.p-dropdown .p-dropdown-trigger {
  width: 2rem;
}
.custom-select.large.p-dropdown .p-dropdown-trigger {
  width: 3rem;
}

.custom-select.small.p-dropdown .p-dropdown-trigger .pi {
  font-size: 0.75rem;
  color: #893f2b;
}
.custom-select.medium.p-dropdown .p-dropdown-trigger .pi {
  font-size: 0.75rem;
  color: #893f2b;
}
.custom-select.large.p-dropdown .p-dropdown-trigger .pi {
  font-size: 1rem;
  color: #893f2b;
}

.custom-select.small .p-dropdown-label {
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  line-height: 1.25rem;
}
.custom-select.medium .p-dropdown-label {
  font-size: 0.875rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
  line-height: 1.25rem;
}
.custom-select.large .p-dropdown-label {
  font-size: 1rem;
  padding: 0.875rem 0 0.875rem 0.825rem;
  line-height: 1.25rem;
}
.custom-select.p-dropdown {
  border-radius: 0.375rem;
  box-shadow: none;
  border-color: #893f2b;
}
.custom-select.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: #893f2b;
}
.custom-select.p-dropdown:not(.p-disabled):hover {
  border-color: #893f2b;
  box-shadow: none;
}
.custom-select-panel span {
  box-shadow: none;
}
.custom-select-panel.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  color: #893f2b;
  background-color: #ffeee6;
}
.custom-select-panel.small.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  /* padding: 8px 12px; */
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}
.custom-select-panel.medium.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
}
.custom-select-panel.large.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 1rem;
  padding: 0.75rem 0.825rem;
}
.custom-select-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  padding: 8px 12px;
}

@layer components {
  .warn-row {
    @apply !bg-yellow-50;
  }
  .datatable-no-footer.p-datatable .p-paginator-bottom {
    @apply !hidden;
  }
  .input-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    @apply !bg-green-700;
  }
  .input-switch.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    @apply !bg-green-800;
  }
  .input-switch.p-inputswitch:not(.p-inputswitch-checked):not(.p-disabled)
    .p-inputswitch-slider {
    @apply !bg-gray-700;
  }
  .input-switch.p-inputswitch .p-inputswitch-slider {
    @apply !ring-0;
  }
}

.custom-multiselect .p-multiselect-label-container {
  display: flex;
  align-items: center;
}
.custom-multiselect .p-multiselect .p-multiselect-trigger {
  color: #893f2b;
}

.custom-multiselect.small.p-multiselect .p-multiselect-trigger {
  width: 1.5rem;
}
.custom-multiselect.medium.p-multiselect .p-multiselect-trigger {
  width: 2rem;
}
.custom-multiselect.large.p-multiselect .p-multiselect-trigger {
  width: 3rem;
}

.custom-multiselect.small.p-multiselect .p-multiselect-trigger .pi {
  font-size: 0.75rem;
  color: #893f2b;
}
.custom-multiselect.medium.p-multiselect .p-multiselect-trigger .pi {
  font-size: 0.75rem;
  color: #893f2b;
}
.custom-multiselect.large.p-multiselect .p-multiselect-trigger .pi {
  font-size: 1rem;
  color: #893f2b;
}

.custom-multiselect-panel.small.p-multiselect-panel .p-multiselect-header {
  padding: 0.25rem 0.5rem;
}
.custom-multiselect-panel.medium.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 0.5rem;
}
.custom-multiselect-panel.large.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 0.825rem;
}

.custom-multiselect.small .p-multiselect-label {
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  line-height: 1.25rem;
}
.custom-multiselect.medium .p-multiselect-label {
  font-size: 0.875rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
  line-height: 1.25rem;
}
.custom-multiselect.large .p-multiselect-label {
  font-size: 1rem;
  padding: 0.925rem 0 0.925rem 0.825rem;
  line-height: 1.25rem;
}
.custom-multiselect.p-multiselect {
  border-radius: 0.375rem;
  box-shadow: none;
  border-color: #893f2b;
}
.custom-multiselect-panel.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:focus {
  box-shadow: none;
  border-color: #893f2b;
}
.custom-multiselect-panel
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-focus {
  box-shadow: none;
  border-color: #893f2b;
}
.custom-multiselect-panel.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-close:focus {
  box-shadow: none;
  border-color: #893f2b;
}
.custom-multiselect-panel.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-close {
  color: #893f2b;
}

.custom-multiselect.p-focus {
  box-shadow: none;
  border-color: #893f2b;
}

.custom-multiselect.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: #893f2b;
}
.custom-multiselect.p-multiselect:not(.p-disabled):hover {
  border-color: #893f2b;
  box-shadow: none;
}
.custom-multiselect-panel span {
  box-shadow: none;
}
.custom-multiselect-panel.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight {
  color: #893f2b;
  background-color: #ffeee6;
}
.custom-multiselect-panel.small.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
}
.custom-multiselect-panel.medium.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item {
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
}
.custom-multiselect-panel.large.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item {
  font-size: 1rem;
  padding: 0.75rem 0.825rem;
}
.custom-multiselect-panel.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item-group {
  padding: 8px 12px;
}

.custom-multiselect-panel .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #893f2b;
  background: #893f2b;
}

.custom-multiselect-panel .p-checkbox .p-checkbox-box.p-focus {
  box-shadow: none;
  border-color: #893f2b;
}

.custom-multiselect-panel
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  border-color: #893f2b;
  background: #893f2b;
}
.custom-multiselect-panel.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  padding-right: 1.75rem;
  padding-left: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.custom-multiselect-panel .p-inputtext,
.p-togglebutton,
.p-selectbutton,
.p-inputgroup {
  box-shadow: none;
}

.custom-multiselect-panel .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: inherit;
}

input.remove-number-arrows::-webkit-outer-spin-button,
input.remove-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].remove-number-arrows {
  -moz-appearance: textfield;
}

input[type="radio"]:checked.custom-radio-button {
  background-color: #893f2b;
  border-color: #893f2b;
}

input[type="radio"]:focus.custom-radio-button {
  outline: none;
  box-shadow: none; /* Adjust the color as needed */
}

input[type="radio"].custom-radio-button {
  accent-color: #893f2b; /* This property sets the color for the radio button in some modern browsers */
  cursor: pointer;
}

input[type="checkbox"]:checked.custom-checkbox {
  background-color: #893f2b;
  border-color: #893f2b;
}

input[type="checkbox"]:focus.custom-checkbox {
  outline: none;
  box-shadow: none; /* Adjust the color as needed */
}

input[type="checkbox"].custom-checkbox {
  cursor: pointer;
  accent-color: #893f2b; /* This property sets the color for the checkbox in some modern browsers */
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #ffd4bf;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.8);
  background-color: #522214;
  border-radius: 0px;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.hide-scrollbar {
  overflow-y: auto;
}
